import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from 'components/ui/Container';
import Button from 'components/ui/Button';

import Img from 'gatsby-image';
import Icon from 'components/ui/Icon';

import * as Styled from './styles';

const Banner = ({ title, subtitle, content, linkTo, linkText, picture}) => (
  <Styled.Banner>
    <Container section>
      <Styled.Content className="sm:text-left">
        <Styled.TitleMobile className="sm:hidden">{title}</Styled.TitleMobile>
        <Styled.SubTitleMobile className="sm:hidden" >{subtitle}</Styled.SubTitleMobile>
        <Styled.Image className="hidden sm:block"><Img fluid={picture.childImageSharp.fluid} alt="hero picture"/></Styled.Image>
        <Styled.Title className="hidden sm:block">{title}</Styled.Title>
        <Styled.SubTitle className="hidden sm:block" >{subtitle}</Styled.SubTitle>
        {content}
        <Styled.Contenedor>
          <Styled.Contenido> 
            <Styled.IconLink className='icons' href='https://github.com/zurvar'>
            <Icon size="lg"
            icon={['fab', 'github-square']} 
            title='Github' />
          </Styled.IconLink>
          </Styled.Contenido>
          <Styled.Contenido> 
            <Styled.IconLink className='icons' href='https://www.linkedin.com/in/zur-vonarburg-shmaria-383ab3197/'>
            <Icon size="lg"
            icon={['fab', 'linkedin']} 
            title='Linkedin' />
          </Styled.IconLink>
          </Styled.Contenido>
          <Styled.Contenido> 
            <Styled.IconLink className='icons' href='https://www.researchgate.net/profile/Zur_Vonarburg-Shmaria'>
            <Icon size="lg"
            icon={['fab', 'researchgate']} 
            title='ResearchGate' />
          </Styled.IconLink>
          </Styled.Contenido>
          <Styled.Contenido> 
            <Styled.IconLink className='icons' href='https://scholar.google.com/citations?user=7237n8kAAAAJ&hl=en'>
            <Icon size="lg"
            icon={['fab', 'google-plus-square']} 
            title='Google Scholar' />
          </Styled.IconLink>
          </Styled.Contenido>
        </Styled.Contenedor>
        </Styled.Content>
        <Link to={linkTo}>
        <Button primary>{linkText}</Button>
        </Link> 
      <Styled.Padded>
      <Link className="hidden sm:block" to="/projects+publications/">
        <Button primary>My Research and Projects</Button>
      </Link>      
      </Styled.Padded>
    </Container>    
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  picture: PropTypes.any.isRequired
};

export default Banner;
