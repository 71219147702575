import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Banner = styled.section`
  ${tw`bg-gray-300 border-b border-indigo-100 -mt-5 sm:mt-0`};
`;

export const Content = styled.p`
  ${tw`mb-8`};
  float:right;
`;

export const Image = styled.figure`
  ${tw`w-1/2  sm:w-1/4 mr-3 border border-green-500 mb-4 sm:mb-0`};
  @media screen and (min-width: 640px) {
    float: left;
    }
  img {
    ${tw`border-2 border-white`};
  }
`;

export const Title = styled.p`
${tw`uppercase -mb-3 text-lg font-bold w-full text-left`};
`;

export const TitleMobile = styled.p`
${tw`uppercase -mb-3 text-lg font-bold w-full visible sm:invisible text-center`};
`;

export const SubTitle = styled.p`
${tw`text-xs mb-2 text-green-500 w-full text-left`};
`;

export const SubTitleMobile = styled.p`
${tw`text-xs mb-2 text-green-500 w-full visible sm:invisible text-center`};
`;

export const Padded = styled.span`
margin-left: 10px;
`;

/* export const Icon = styled.span`
  ${tw`flex items-center justify-center w-10 h-10 border border-green-500 rounded-full mb-2`};
`; */

export const Icon = styled.span`
  ${tw`flex w-12 h-14 mb-2`};
`;

export const IconLink = styled.a`
  color:inherit;
  width: 50px;
  height: 50px;
  @media screen and (max-width: 640px) {
    font-size: 1.5em;
  }
  
`;


export const Contenido = styled.div`
  ${tw`mr-5 sm:mr-2 hidden sm:block`};
  margin-top:5px;
  float:left;
`;

export const Contenedor = styled.div`
@media screen and (max-width: 640px) {
    display: flex;
    justify-content: center;
  }
  
`;